import styled from "styled-components";

export const AreaCards = styled.li`
    width: 95vw;
    max-width: 313.5px;
    height: 40vh;
    border: 2px solid var(--primary-color);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    div{
    width: 100vw;
    height: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
}

    img{
        width: 30%;
        
    }

    p{
        color: var(--primary-color);
        font-size: 1rem;
        font-weight: 800;
        text-align: center;
        width: 70%
    }

    @media (min-width: 768px) {
        width: 13em;
        height: 12em;
        border: 2px solid var(--primary-color);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        padding: 3rem 1rem;

        div{
            width: 100%;
            height: auto;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            /* outline: 1px red solid; */
        }
        
        img{
            width: 30%;
        }
        
        
        p{
            color: var(--primary-color);
            font-size: 1em;
            font-weight: 800;
            text-align: center;
            width: 100%;
            word-wrap: break-word;
            box-sizing: border-box;

    }
  }
    @media (min-width: 1440px) {
        width: 14em;
        height: 13em;
        border: 2px solid var(--primary-color);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        padding: 3rem 1rem;

        div{
            width: 100%;
            height: auto;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            /* outline: 1px red solid; */
        }
        
        img{
            width: 30%;
        }
        
        
        p{
            color: var(--primary-color);
            font-size: 1em;
            font-weight: 800;
            text-align: center;
            width: 100%;
            word-wrap: break-word;
            box-sizing: border-box;

    }
  }
`;