import styled from "styled-components";
import imagemAdorno from "../../Assets/Consultoria/adornoJOBS.svg";
import fundoBranco from "../../Assets/Fundos/fundoBrancoFormatado1.png";

export const ConsultoriaStyle = styled.div`
  margin-top: 14em;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: var(--fontSize);

  .esquerda {
    width: 100vw;
    min-height: 50vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: content-box;
    margin-top: -3em;
  }
  .esquerda > div {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .esquerda > div > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .esquerda > div > div > img {
    width: 90%;
  }

  .esquerda > div > p {
    width: 90%;
    color: var(--menu-blue-color);
    font-size: 1em;
    font-weight: 700;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .esquerda > div > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-top: 10px;
    gap: 25px;
    display: flex;
    width: 89%;

    > li {
      position: relative;
      padding-left: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      font-size: 1em;
    }

    li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
    }
  }

  .esquerda > div > button {
    background-color: var(--primary-color);
    color: var(--bottom-primary-collor-white);
    width: 90%;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 10px;
    margin-top: 20px;
  }

  .direita {
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 300px;
    }
  }

  @media (min-width: 768px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    font-size: var(--fontSize);

    .esquerda {
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0;
      box-sizing: content-box;
      z-index: 100;
      margin-top: 0;
    }
    .esquerda > div {
      padding: 5%;
      width: 100%;
    }

    .esquerda > div > div {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: flex-start;
      padding-left: 18%;
    }

    .esquerda > div > div > img {
      width: 10em;
      margin-left: 1%;
    }

    .esquerda > div > p {
    
      color: var(--menu-blue-color);
      font-size: 0.5em;
      font-weight: 600;
      word-wrap: break-word;
      overflow-wrap: break-word;
    margin-left: 25%;
    }

    .esquerda > div > ul {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 15px;
      margin-left: 25%;
      margin-bottom: 15px;

      li {
        position: relative;
        padding-left: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 129px;
        font-size: 0.6em;
        font-weight: 400;
        word-wrap: break-word;
      }

      li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: var(--primary-color);
      }
    }

    .esquerda > div > button {
      background-color: var(--primary-color);
      color: var(--bottom-primary-collor-white);
      max-width: 200px;
      font-size: 0.6em;
      font-weight: bold;
      margin-top: 10px;
      border-radius: 15px;
      margin-left: 10%;
    }

    .direita {
      height: 50vh;

      > img {
        width: 50vw;
      }
    }
  }

  @media (min-width: 1024px) {
    margin-top: 8em;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    font-size: var(--fontSize);

    .esquerda {
      width: 50vw;
      min-height: 50vh;
      padding: 1 5%;
      display: flex;
      flex-direction: column;
      gap: 0;
      box-sizing: content-box;
      z-index: 100;
    }

    .esquerda > div {
      padding: 5%;
      width: 100%;
    }

    .esquerda > div > div {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: flex-start;
      padding-left: 10%;
    }

    .esquerda > div > div > img {
      margin-left: 0;
      width: 16em;
    }

    .esquerda > div > div {
      width: 100%;
    }

    .esquerda > div > p {
      width: 90%;
      color: var(--menu-blue-color);
      font-size: 0.8em;
      font-weight: 700;
      word-wrap: break-word;
      overflow-wrap: break-word;
      margin-left: 10%;
    }

    .esquerda > div > ul {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 25px;
      margin-left: 10%;

      li {
        position: relative;
        padding-left: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 21.25em;
        font-size: 0.6em;
        font-weight: 400;
        word-wrap: break-word;
      }

      li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: var(--primary-color);
      }
    }

    .esquerda > div > button {
      background-color: var(--primary-color);
      color: var(--bottom-primary-collor-white);
      max-width: 300px;
      font-size: 0.8em;
      font-weight: bold;
      margin-top: 20px;
      border-radius: 15px;
      width: 283px;
      margin-left: 10%;
    }

    .direita {
      min-height: 50vh;
    }
  }

  @media (min-width: 1440px) {
    .esquerda {
      width: 50vw;
      min-height: 50vh;
      padding: 1 5%;
      display: flex;
      flex-direction: column;
      gap: 0;
      box-sizing: content-box;
      z-index: 100;
      margin-bottom: 6em;
    }

    .esquerda > div {
      padding: 5%;
      width: 100%;
    }

    .esquerda > div > div {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: flex-start;
      padding-left: 18%;
    }

    .esquerda > div > div > img {
      margin-left: 0;
      width: 18em;
    }

    .esquerda > div > p {
      width: 90%;
      color: var(--menu-blue-color);
      font-size: 0.9em;
      font-weight: 700;
      word-wrap: break-word;
      overflow-wrap: break-word;
      margin-left: 25%;
    }

    .esquerda > div > ul {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 25px;
      margin-left: 25%;

      li {
        position: relative;
        padding-left: 20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        width: 21.25em;
        font-size: 0.9em;
        font-weight: 400;
        word-wrap: break-word;
       
      }

      li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: var(--primary-color);
      }
    }

    .esquerda > div > button {
      background-color: var(--primary-color);
        color: var(--bottom-primary-collor-white);
        max-width: 300px;
        font-size: 0.8em;
        font-weight: bold;
        margin-top: 20px;
        border-radius: 15px;
        width: 283px;
        margin-left: 17%;
        align-self: baseline;
    }

    .direita > img {
      width: 60%;
    }
  }

  
`;

export const ConsultoriaStyle2 = styled.div`
  font-size: var(--fontSize);
  .containerbranco {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto 200px;
    width: 100vw;
    position: relative;
    margin-top: 0;
    margin-top: -25px;
    // outline: 1px red solid;

    @media (max-height: 600px) {
      margin-top: -20px;
    }

    @media (min-width: 1050px) {
      margin-top: -80px;
    }

    @media (min-width: 850px) {
      margin-top: -100px;
    }
  }

  .top {
    width: calc(100% + 8px);
    height: 80px;
    background-image: url(${fundoBranco});
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    margin-top: -41px;
    margin-left: -4px;
    transform: scaleX(-1);
  }

  .conteudo {
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bottom-primary-collor-white);
    color: #fff;
    box-sizing: border-box;
    padding: 20px 20px;
    max-width: 100vw;
    //outline: 1px red solid;
  }

  .foot {
    width: calc(100% + 8px);

    background-image: url(${fundoBranco});
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    margin-top: -4px;
    margin-left: -4px;
  }
  /* .text {
    width: 80%;
    max-width: calc(100dvw - 20%);
    display: flex;
    flex-direction: column;
    gap: 90px;
} */

  .parteDeCima {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;

    margin-top: 10px;
    /* margin-bottom: 70px; */

    > div > h2 {
      color: var(--primary-color);
      width: 90%;
      text-align: left;
      font-weight: bold;
      font-size: 24px;
      width: 100%;
      br {
        display: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
    }

    > div p {
      font-size: 1em;
      line-height: 1em;
      font-weight: 500;
      color: var(--primary-color);
      text-align: justify;
      width: 100%;
    }

    > div span {
      font-size: 0.8em;
      font-weight: bold;
      text-align: justify;
      width: 85%;
    }

    p {
      font-size: 0.8em;
      font-weight: 500;
      color: var(--primary-color);
      text-align: justify;
      width: 85%;
    }
  }

  .meio {
    width: 100vw;

    display: flex;
    flex-direction: column;
    gap: 15px;

    .superior {
      width: 90vw;
      margin: 0 auto;

      > h3 {
        font-size: 1.5em;
        font-weight: bold;
        color: var(--primary-color);
        text-align: left;
      }
    }

    .inferior {
      width: 90%;

      > img {
        width: 100%;
      }
    }
  }

  .parteDeBaixo {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 20px;
    margin-top: 30px;

    > h4 {
      font-size: 1.5em;
      font-weight: bold;
      color: var(--primary-color);
      text-align: left;
      width: 90vw;
      margin: 0 auto;
    }

    > ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      gap: 20px;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 80%;
        height: 30vh;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        span {
          font-size: 3em;
          font-weight: BOLD;
          color: var(--primary-color);
          width: 100%;
          text-align: center;
        }

        p {
          font-size: 1em;
          font-weight: bold;
          width: 100%;
          text-align: center;
          line-height: 1em;
          color: var(--menu-blue-color);
word-wrap: break-word;
        }
      }
    }
  }

  @media (min-width: 768px) {
    /* width: 100vw;
    min-height: 210vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${(props) => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 110%;
    display: flex;
    flex-direction: column;
    gap: 30px; */
    /* outline: 1px red solid; */

    .top {
      height: 120px;
      margin-top: -7%;
      margin-left: -4px;
      transform: scaleX(-1);
    }

    .parteDeCima {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 70px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
        width: 90%;
        margin: 0 auto;

        > h2 {
          &::before {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 58%;
            left: -14px;
            transform: translate(-100%, -7.5px);
            height: 5px;
            background-color: var(--primary-color);
          }

          color: var(--primary-color);
          width: 90%;
          text-align: left;
          font-weight: bold;
          font-size: 24px;
          position: relative;
          width: 33%;
          text-align: left;
        }

        p {
          font-size: 0.8em;
          font-weight: 500;
          color: var(--primary-color);
          text-align: left;
          width: 33%;
        }
      }

      span {
        font-size: 1em;
        font-weight: bold;
        text-align: justify;
        width: 27%;
        text-align: left;
      }
    }

    .meio {
      width: 100vw;
      height: 33%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .superior {
        width: 100vw;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;

        > h3 {
          font-size: 2em;
          font-weight: bold;
          color: var(--primary-color);
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .inferior {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bpttom: 50px;

        > img {
          width: 70%;
        }
      }
    }

    .parteDeBaixo {
      width: 100vw;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 0;

      > h4 {
        font-size: 2em;
        font-weight: bold;
        color: var(--primary-color);
        text-align: center;
        margin: 32px;
      }

      > ul {
        display: flex;
        flex-direction: column;
        width: 100vw;
        gap: 100px;

        > li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          width: 300px;
          height: 300px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          span {
            font-size: 4em;
            color: var(--primary-color);
          }

          > p {
            font-size: 1em;
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .top {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${fundoBranco});
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -7%;
      margin-left: -4px;
      transform: scaleX(-1);
    }

    .parteDeCima {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 70px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
        width: 90%;
        margin: 0 auto;

        > h2 {
          &::before {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 58%;
            left: -14px;
            transform: translate(-100%, -7.5px);
            height: 5px;
            background-color: var(--primary-color);
          }

          color: var(--primary-color);
          width: 90%;
          text-align: left;
          font-weight: bold;
          font-size: 32px;
          position: relative;
          width: 33%;
          text-align: left;
        }

        p {
          font-size: 1em;
          font-weight: 500;
          color: var(--primary-color);
          text-align: left;
          width: 33%;
        }
      }

      span {
        font-size: 1em;
        font-weight: bold;
        text-align: justify;
        width: 27%;
        text-align: left;
      }
    }

    .meio {
      width: 100vw;
      height: 33%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .superior {
        width: 100vw;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;

        > h3 {
          font-size: 3em;
          font-weight: bold;
          color: var(--primary-color);
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .inferior {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bpttom: 50px;

        > img {
          width: 70%;
        }
      }
    }

    .parteDeBaixo {
      width: 100vw;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 0;

      > h4 {
        font-size: 2.5em;
        font-weight: bold;
        color: var(--primary-color);
        text-align: center;
        margin: 32px auto;
      }

      > ul {
        display: grid;
        grid-template-columns: 250px 250px 250px;
        width: 100vw;
        gap: 50px;

        > li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          width: 250px;
          height: 250px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          span {
            font-size: 4em;
            color: var(--primary-color);
            text-align: center;
          }

          > p {
            font-size: 0.9em;
            font-weight: bold;
            text-align: center;

          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top {
      width: calc(100% + 8px);
      height: 220px;
      background-image: url(${fundoBranco});
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -120px;
      margin-left: -4px;
      transform: scaleX(-1);
    }

    .parteDeCima {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 70px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
        width: 90%;
        margin: 0 auto;

        > h2 {
          &::before {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 58%;
            left: -14px;
            transform: translate(-100%, -7.5px);
            height: 5px;
            background-color: var(--primary-color);
          }

          color: var(--primary-color);
          width: 100%;
          text-align: left;
          font-weight: bold;
          font-size: 40px;
          position: relative;
          width: 33%;
          text-align: left;
          word-wrap: break-word;

          br {
            display: block;
          }
        }

        p {
          font-size: 1.1em;
          font-weight: 500;
          color: var(--primary-color);
          text-align: left;
          width: 33%;
        }
      }

      span {
        font-size: 1em;
        font-weight: bold;
        text-align: justify;
        width: 27%;
        text-align: left;
      }
    }

    .meio {
      width: 100vw;
      height: 33%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .superior {
        width: 100vw;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;

        > h3 {
          font-size: 3em;
          font-weight: bold;
          color: var(--primary-color);
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .inferior {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bpttom: 50px;

        > img {
          width: 70%;
        }
      }
    }

    .parteDeBaixo {
      width: 100vw;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 0;

      > h4 {
        font-size: 2.5em;
        font-weight: bold;
        color: var(--primary-color);
        text-align: center;
        margin: 32px auto;
      }

      > ul {
        display: grid;
        grid-template-columns: 15.63em 15.63em 15.63em;
        width: 100vw;

        > li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          width: 15.63em;
          height: 15.63em;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          span {
            font-size: 4em;
            color: var(--primary-color);
          }

          > p {
            font-size: 0.9em;
            font-weight: bold;
            text-align: left;
            width: 90%;
            text-align: center;
          }
        }
      }
    }

    .foot {
      display: none;
    }
  }
`;

export const ConsultoriaStyle3 = styled.section`
  font-size: var(--fontSize);

  .containerAzul {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px auto 200px;
    width: 100vw;
    position: relative;
    margin-top: 0;
    font-size: var(--fontSize);

    @media (max-height: 600px) {
      margin-top: -20px;
    }

    @media (min-width: 1050px) {
      margin-top: -80px;
    }

    @media (min-width: 850px) {
      margin-top: -100px;
    }
  }

  .top {
    width: calc(100% + 8px);
    height: 200px;
    background-image: url(${imagemAdorno});
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    margin-top: 4px;
    margin-left: -4px;
  }

  .conteudo {
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2b9bc6;
    color: #fff;
    box-sizing: border-box;
    padding: 0 auto;
    margin-top: -120px;
    z-index: 5;
    gap: 50px;

    > div {
      width: 90vw;
    }

    > div > p {
      font-size: 1.5em;
      text-align: center;

      strong {
        font-weight: bold;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 100vw;
      gap: 50px;
      justify-content: center;
      align-items: center;
      /* outline:1px red solid; */

      .card {
        background-color: var(--bottom-primary-collor-white);
        display: flex;
        flex-direction: column;

        width: 300px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* outline: 1px red solid; */

        .cardSuperior {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 50%;
          padding: 10%;

          > img {
            width: 50%;
            align-self: center;
          }
        }

        .cardInferior {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 50%;
          padding: 5%;

          p {
            font-size: 1em;
            font-weight: bold;
            color: var(--menu-blue-color);
            text-align: left;
            /* outline:1px red solid; */
          }
        }
      }
    }

    .container {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;

      > h3 {
        font-size: 1.5em;
        font-weight: 500;
        text-align: left;
        width: 90%;

        br {
          display: none;
        }
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }

      
    }
  }

  /* .text {
    width: 80%;
    max-width: calc(100dvw - 20%);
    display: flex;
    flex-direction: column;
    gap: 90px;
} */

  .foot {
    width: calc(100% + 8px);
    height: 100px;
    background-image: url(${imagemAdorno});
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    margin-top: -4px;
    margin-left: -4px;
  }

  @media (min-width: 768px) {
    .containerAzul {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 200px auto 200px;
      width: 100vw;
      position: relative;
      margin-top: 0;
      font-size: var(--fontSize);

      @media (max-height: 600px) {
        margin-top: -20px;
      }

      @media (min-width: 1050px) {
        margin-top: -80px;
      }

      @media (min-width: 850px) {
        margin-top: -100px;
      }
    }

    .top {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${imagemAdorno});
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: 4px;
      margin-left: -4px;
    }

    .conteudo {
      position: relative;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #2b9bc6;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;
      gap: 90px;

      > div {
        width: 100vw;
      }

      > div > p {
        font-size: 2em;
        text-align: center;
        width: 90vw;
        margin: 0 auto;

        strong {
          font-weight: bold;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        width: 100vw;
        gap: 100px;
        justify-content: center;
        align-items: center;

        .card {
          background-color: var(--bottom-primary-collor-white);
          display: flex;
          flex-direction: column;
          width: 18.75em;
          height: 18.75em;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          .cardSuperior {
            display: flex;
            justify-content: left;
            align-items: center;
            min-height: 150px;
            padding: 10%;
            /* outline:1px red solid; */

            > img {
              width: 50%;
              align-self: center;
            }
          }

          .cardInferior {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 150px;
            padding: 10%;

            p {
              font-size: 1em;
              font-weight: bold;
              color: var(--menu-blue-color);
              /* outline:1px red solid; */
            }
          }
        }
      }

      .container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;

        > h3 {
          font-size: 2em;
          font-weight: 500;
          text-align: center;
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }

        
      }
    }

    /* .text {
    width: 80%;
    max-width: calc(100dvw - 20%);
    display: flex;
    flex-direction: column;
    gap: 90px;
} */

    .foot {
      width: calc(100% + 8px);
      height: 100px;
      background-image: url(${imagemAdorno});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -4px;
      margin-left: -4px;
    }
  }
  @media (min-width: 1024px) {
    .containerAzul {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 200px auto 200px;
      width: 100vw;
      position: relative;
      margin-top: 44px;
      font-size: var(--fontSize);
    }

    .top {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${imagemAdorno});
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: 4px;
      margin-left: -4px;
    }

    .conteudo {
      position: relative;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #2b9bc6;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;
      gap: 90px;

      > div {
        width: 100vw;
      }

      > div > p {
        font-size: 2em;
        text-align: center;

        strong {
          font-weight: bold;
        }
      }

      ul {
        display: grid;
        grid-template-columns: auto auto auto auto;
        width: 100vw;
        gap: 50px;
        justify-content: center;
        align-items: center;

        .card {
          background-color: var(--bottom-primary-collor-white);
          display: flex;
          flex-direction: column;
          /* justify-content: center;
        align-items: center; */
          width: 9em;
          height: 9em;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          .cardSuperior {
            display: flex;
            justify-content: left;
            align-items: center;
            min-height: 50%;
            padding: 10%;
            /* outline:1px red solid; */

            > img {
              width: 50%;
              align-self: center;
            }
          }

          .cardInferior {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50%;
            padding: 10%;

            p {
              font-size: 0.6em;
              font-weight: bold;
              color: var(--menu-blue-color);
              text-align: left;
              /* outline:1px red solid; */
            }
          }
        }
      }

      .container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        > h3 {
          font-size: 1.5em;
          font-weight: 500;
          text-align: center;
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }

        
      }
    }

    /* .text {
    width: 80%;
    max-width: calc(100dvw - 20%);
    display: flex;
    flex-direction: column;
    gap: 90px;
} */

    .foot {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${imagemAdorno});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -4px;
      margin-left: -4px;
    }
  }
  @media (min-width: 1440px) {
    .containerAzul {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 200px auto 200px;
      width: 100vw;
      position: relative;
      margin-top: 44px;
      font-size: var(--fontSize);
    }

    .top {
      width: calc(100% + 8px);
      height: 215px;
      background-image: url(${imagemAdorno});
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -41px;
      margin-left: -4px;
    }

    .conteudo {
      position: relative;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #2b9bc6;
      color: #fff;
      box-sizing: border-box;
      padding: 20px 20px;
      max-width: 100vw;
      gap: 90px;

      > div {
        margin-top: 30px;
        width: 50vw;
      }

      > div > p {
        font-size: 2em;
        text-align: left;

        strong {
          font-weight: bold;
        }
      }

      ul {
        display: grid;
        grid-template-columns: 12.5em 12.5em 12.5em 12.5em;
        width: 100vw;
        gap: 50px;
        justify-content: center;
        align-items: center;

        .card {
          background-color: var(--bottom-primary-collor-white);
          display: flex;
          flex-direction: column;

          width: 12.5em;
          height: 12.5em;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          .cardSuperior {
            display: flex;
            justify-content: left;
            align-items: center;
            min-height: 50%;
            padding: 10%;
            /* outline:1px red solid; */

            > img {
              width: 50%;
              align-self: center;
            }
          }

          .cardInferior {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 50%;
            padding: 10%;

            p {
              font-size: 0.8em;
              font-weight: bold;
              color: var(--menu-blue-color);
              text-align: left;
              /* outline:1px red solid; */
            }
          }
        }
      }

      .container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        > h3 {
          font-size: 1.8em;
          font-weight: 500;
          text-align: center;
          line-height: 1em;

          br {
            display: block;
          }
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }

       
      }
    }

    /* .text {
    width: 80%;
    max-width: calc(100dvw - 20%);
    display: flex;
    flex-direction: column;
    gap: 90px;
} */

    .foot {
      width: calc(100% + 8px);
      height: 200px;
      background-image: url(${imagemAdorno});
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
      margin-top: -4px;
      margin-left: -4px;
    }
  }
`;
