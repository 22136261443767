import {
    ContainerFikSmart, TopSection, MidSection, BottomSection, MainDiv, LogoContainer, TextBoldContainer, TextContainer, LogoImage, Button, TopBackground,
    SubTitleLine, ButtonGroup, NonClickableButton, IconButton, WriteTextBoldContainer, NonClickableGrayButton, GrayButtomTextContainer, NonClickableWriteButton,
    BottomTextContent, BottomTitle, BlueButtomTextBoldContainer, BlueButtomTextContainer, InvertedSubTitleLine, WriteButtomTextBoldContainer,
    WriteButtomTextContainer, NonClickableLightBlueButton, ButtonsWrapper, LightBlueButtomTextBoldContainer, LightBlueButtomTextContainer
}
    from "./style";
import { useContext } from "react";
import FikContext from "../../Contexts/FikSiteContext";

import FikSmartLogo from "../../Assets/FikSmart/FikSmartLogo.svg";
import TopBackgnd from '../../Assets/FikSmart/TopBackground.png';
import IconButton1 from '../../Assets/FikSmart/Icone01.svg';
import IconButton2 from '../../Assets/FikSmart/Icone02.svg';
import IconButton3 from '../../Assets/FikSmart/Icone03.svg';
import IconButton4 from '../../Assets/FikSmart/Icone04.svg';
import IconButton5 from '../../Assets/FikSmart/Icone05.svg';
import IconButton6 from '../../Assets/FikSmart/Icone06.svg';
import IconButton7 from '../../Assets/FikSmart/Icone07.svg';
import IconButton8 from '../../Assets/FikSmart/Icone08.svg';
import IconButton9 from '../../Assets/FikSmart/Icone09.svg';
import IconButton10 from '../../Assets/FikSmart/Icone10.svg';
import IconButton11 from '../../Assets/FikSmart/Icone11.svg';
import IconButton12 from '../../Assets/FikSmart/Icone12.svg';
import BottomImg01 from '../../Assets/FikSmart/BottomImg01.png';
import BottomImg02 from '../../Assets/FikSmart/BottomImg02.png';
import BottomImg03 from '../../Assets/FikSmart/BottomImg03.png';

export const PageFikSmart = ({

}) => {

    const { handleClick } = useContext(FikContext);

    return (
        <ContainerFikSmart>
            <TopSection>
                <TopBackground src={TopBackgnd} alt="Foto de Homem com Notebook" />
                <MainDiv>
                    <LogoContainer>
                        <LogoImage src={FikSmartLogo} alt="Logomarca FikSmart" />
                    </LogoContainer>
                    <TextBoldContainer>
                        <p>TRANSFORME A APRENDIZAGEM<br />DA SUA EQUIPE COM O FIK SMART</p>
                    </TextBoldContainer>
                    <TextContainer>
                        <p>Acelere a transformação de aprendizagem<br />com a nossa plataforma LMS</p>
                    </TextContainer>
                    <Button ariaLabel='Fale com nosso time pelo whatsapp.' onClick={handleClick}>
                        FALE COM O NOSSO TIME
                    </Button>
                </MainDiv>
            </TopSection>
            <MidSection>
                <div className="topFaixaBranca"></div>
                <div className="containerOQEFikSmart">
                    <WriteTextBoldContainer>
                        O QUE É O<br /> FIK SMART?
                    </WriteTextBoldContainer>
                    <NonClickableGrayButton>
                        <GrayButtomTextContainer>
                            O <b>FIK Smart</b> é uma plataforma LMS
                            desenvolvida pela FIK para criar,
                            gerenciar e otimizar programas de
                            treinamento, centralizando e
                            organizando todas as atividades
                            de ensino em um ambiente digital.
                        </GrayButtomTextContainer>
                    </NonClickableGrayButton>
                </div>
                <div className="footFaixaBranca"></div>
            </MidSection>
            <MidSection>
                <SubTitleLine>
                    PRINCIPAIS FUNCIONALIDADES:
                </SubTitleLine>
                <ButtonGroup>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton1} alt="Icone de logomarcas de conteúdos digitais" />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            CRIAÇÃO DE CONTEÚDOS
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Cursos com textos,
                            vídeos e quizzes.
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton2} alt="Icone de conexão entre pessoas" />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            GERENCIAMENTO DE USUÁRIOS
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Administração de perfis
                            e grupos de estudo.
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton3} alt="Icone de uma videoaula" />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            ENTREGA DE CONTEÚDOS
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Distribuição eficiente
                            de materiais.
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                </ButtonGroup>
                <ButtonGroup>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton4} alt="Icone de caixas de diálogo" />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            INTERAÇÃO E COMUNICAÇÃO
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Fóruns, chats e
                            mensagens internas.
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton5} alt="Icone de sinal positivo com estrelas de avaliação" />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            AVALIAÇÃO E FEEDBACK
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Testes e feedback
                            sobre desempenho
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                    <NonClickableButton>
                        <IconButton>
                            <img src={IconButton6} alt="Icone gráficos sendo analisados por uma lupa." />
                        </IconButton>
                        <BlueButtomTextBoldContainer>
                            RELATÓRIOS E ANÁLISES
                        </BlueButtomTextBoldContainer>
                        <BlueButtomTextContainer>
                            Relatórios detalhados
                            sobre progresso
                        </BlueButtomTextContainer>
                    </NonClickableButton>
                </ButtonGroup>
                <InvertedSubTitleLine>
                    BENEFÍCIOS DE IMPLEMENTAÇÃO:
                </InvertedSubTitleLine>
                <ButtonGroup>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton7} alt="Icone de pincel sobre a tela de um computador" />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            ACESSIBILIDADE:
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Acesso de qualquer lugar
                            e a qualquer hora.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton8} alt="Icone de dedo indicador apontando para cima" />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            CENTRALIZAÇÃO:
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Gestão de todas as atividades
                            em uma única plataforma.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton9} alt="Icone de logomarcas de aplicativos de um celular" />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            ECONOMIA:
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Automatização de tarefas
                            administrativas e redução de custos.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                </ButtonGroup>
                <ButtonGroup>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton10} alt="Icone de uma moeda sendo contornada por uma seta" />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            PERSONALIZAÇÃO:
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Programas de aprendizagem
                            adaptados.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton11} alt="Icone de pessoas com uma caixa de diálogo contendo estrelas de avaliação" />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            AVALIAÇÃO E FEEDBACK
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Testes e feedback
                            sobre desempenho.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                    <NonClickableWriteButton>
                        <IconButton>
                            <img src={IconButton12} alt="Icone de caixas de diálogo contendo pessoas, sinal positivo, e coração." />
                        </IconButton>
                        <WriteButtomTextBoldContainer>
                            ENGAJAMENTO:
                        </WriteButtomTextBoldContainer>
                        <WriteButtomTextContainer>
                            Gamificação e
                            vídeos interativos.
                        </WriteButtomTextContainer>
                    </NonClickableWriteButton>
                </ButtonGroup>
            </MidSection>
            <BottomSection>
                <div className="containerAplicacoes">
                    <div className="top"></div>
                    <div className="conteudoAplicacoes">
                        <BottomTextContent>
                            <BottomTitle>Aplicações</BottomTitle>
                        </BottomTextContent>
                        <ButtonsWrapper>
                            <NonClickableLightBlueButton imgSrc={BottomImg01} alt="Foto de Crianças estudando em computadores">
                                <LightBlueButtomTextBoldContainer>
                                    INSTITUIÇÕES DE ENSINO:
                                </LightBlueButtomTextBoldContainer>
                                <LightBlueButtomTextContainer>
                                    Cursos online e suporte
                                    para aulas presenciais.
                                </LightBlueButtomTextContainer>
                            </NonClickableLightBlueButton>
                            <NonClickableLightBlueButton imgSrc={BottomImg02} alt="Foto de pessoas assentadas para uma reunião em uma empresa">
                                <LightBlueButtomTextBoldContainer>
                                    EMPRESAS:
                                </LightBlueButtomTextBoldContainer>
                                <LightBlueButtomTextContainer>
                                    Cursos online e suporte
                                    para aulas presenciais.
                                </LightBlueButtomTextContainer>
                            </NonClickableLightBlueButton>
                            <NonClickableLightBlueButton imgSrc={BottomImg03} alt="Foto de um homem usando um notebook com headphones assistindo uma aula online.">
                                <LightBlueButtomTextBoldContainer>
                                    VENDAS DE CURSOS:
                                </LightBlueButtomTextBoldContainer>
                                <LightBlueButtomTextContainer>
                                    Plataforma para vender cursos online.
                                </LightBlueButtomTextContainer>
                            </NonClickableLightBlueButton>
                        </ButtonsWrapper>
                    </div>
                    <div className="foot"></div>
                </div>
            </BottomSection>
        </ContainerFikSmart>
    );
};
