import styled from "styled-components";

export const FaixaStyle = styled.div`
  width: 80vw;
  height: auto;
  border: 2px solid var(--primary-color);
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: var(--fontSize);
  position: relative;


  /* Estilos para telas maiores que 768px */
  @media (min-width: 768px) {
    width: 100%;

    .faixa-card {
      display: flex;
      width: 100%;
      background-color: transparent;
      font-size: 1em;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 0;
      gap: 0;
    }

    .faixa-card > div {
      width: 100%;
      display: grid;
      grid-template-columns: 80px auto;
      position: relative;
      min-height: 67px;
      z-index: 100;
      background-color: transparent;
    }

    .faixa-card > div >div .icon {
      width: 32px;
      height: auto;
      top: calc(50% - 22px);
      position: relative;
    left: 3%;
    top: 15%;
      z-index: 200;
    }

    .imgFaixa {
      display: flex;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    .faixa-card > div > div .details {
      color: var(--primary-color);
      font-size: 0.98em;
      font-weight: bold;
      width: 100%;
      position: relative;
      top: calc(50% - 4px);
      transform: translateY(-50%);
      left: 0;
      top: 62%;
      text-align: center;
    }

    
  }

  @media (min-width: 1024px) {


    .faixa-card{
      height: auto;
    }

    .faixa-card > div > div {
      width: 100%;
    
    }

    .faixa-card > div > div > .icon {
      position: relative;
      left: 29%;
    }
    
    .faixa-card > div > div > .details {
      font-size: 1em;
        font-weight: 800;
        text-align: center;
        width: 100%;
        color: var(--primary-color);
        position: relative;
        left: 2%;
        top: 60%;
    }
    .imgFaixa {
      display: flex;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: -4px;
    }

    /* .details {
      color: var(--primary-color);
      font-size: 0.9em;
      font-weight: bold;
      width: 98%;
      position: relative;
      top: calc(50% - 4px);
      transform: translateY(-50%);
      left: 0;
    } */
  }

  @media (min-width: 1440px) {
    .faixa-card > div > div > .icon {
      position: relative;
      left: 29%;
    }
    
    .faixa-card > div > div > .details {
      font-size: 1.3em;
        font-weight: 800;
        text-align: center;
        width: 100%;
        color: var(--primary-color);
        position: relative;
        left: 2%;
        top: 60%;
    }

    .imgFaixa {
      display: flex;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 15px;
    }
  }

  /* Estilos para telas menores que 768px */
  @media (max-width: 767px) {
   
    .faixa-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;
      background-color: white;
      border-radius: 15px;
      width: 100%;
    }

    .faixa-card > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 14em;
      gap: 1em;
    }

    .faixa-card > div > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .faixa-card > div > div > .icon {
      width: 6em;
    }

    .faixa-card > div > div > .details {
      font-size: .9em;
      font-weight: 800;
      text-align: center;
      width: 80%;
      color: var(--primary-color);
    }

    .imgFaixa {
    display: none;
    }
  }
`;
