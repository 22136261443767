import styled from 'styled-components';

export const PrincipaisMercados = styled.div`

.containerAzulPrincipaisMercados {
    display: grid;
    grid-template-columns: 1fr;
    width: 100vw;
    position: relative;
    margin-top: 10vh;
    
    z-index: 1;

    @media (max-width: 768px) {
        
      }

}

.conteudoPrincipaisMercados {
    position: relative;
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--primary-color);
    color: #FFF;
    box-sizing: border-box;
    padding: 20px 20px;
    max-width: 100vw;

    h3{
      font-size: 2.5em;
      font-weight: bold;
      color: #ffffff;
      text-align: left;
      margin-left: 9vw; 
      margin-top: 5vh;
      margin-bottom: 3vh;  
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

      @media (max-width: 768px) {
        font-size: 1.2em;
      }
    }

    h4{
      font-size:0.6em;    
      text-align: center;
      margin-top: 0.5vh
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
}

.ulCardPrincipaisMercados{
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, auto));
  width: 100%;
  justify-content:center;
  flex-direction:row;
  align-items:center;
  font-size: var(--fontSize);
  min-height:95px;
  gap:3px;
  
}

.figurePrincipaisMercados {
  height: 90px;
  width: 100px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 50px;
    max-width: 100%;
  }
}

`;