import React, { useState, useEffect, useContext } from 'react';
import { Galleria } from 'primereact/galleria';
import { Carousel } from 'primereact/carousel';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import FikContext from "../../Contexts/FikSiteContext";
import { BarraPrincipaisMercados } from '../../Components/BarraPrincipaisMercados';

import {
    ContainerQuemSomos, MidSection, MidTitle, NonClickableBlueButton, ButtonGroup, TransparentButton, TransparentButtomTextBoldContainer, TransparentButtomTextContainer,
    NonClickableLittleBlueButton, TextsContainer, BlackMidTitle, BlackLittleMidTitle, MinorMidTitle, LeftText, RightText, OtherMidTitle, MaosContainer, BottomSection,
    FaixaEventosTextContainer, FaixaEventosTitle, FaixaEventosButton, TrabalheConoscoContainer, TrabalheConoscoTextContainer, TrabalheConoscoImage, BottomTitle,
    BlackBottomSubTitle, BottomText, Button, TransparentButtonGroup
}
    from "./style";

import logoTora from "../../Assets/LogoClientes/Logos-01.png";
import logoABRH from "../../Assets/LogoClientes/Logos-02.png";
import logoVinci from "../../Assets/LogoClientes/Logos-03.png";
import logoCJF from "../../Assets/LogoClientes/Logos-04.png";
import logoKontabilita from "../../Assets/LogoClientes/Logos-05.png";
import logoGrupoZelo from "../../Assets/LogoClientes/Logos-06.png";
import logoGugs from "../../Assets/LogoClientes/Logos-07.png";
import logoClubeChalezinho from "../../Assets/LogoClientes/Logos-08.png";
import logoAiltoFerreiraFariaAdvAssociados from "../../Assets/LogoClientes/Logos-09.png";
import logoRobbyson from "../../Assets/LogoClientes/Logos-10.png";
import logoAeC from "../../Assets/LogoClientes/Logos-11.png";
import logoVerdemar from "../../Assets/LogoClientes/Logos-12.png";
import Maos from "../../Assets/QuemSomos/Maos.png";
import eventos01 from "../../Assets/QuemSomos/eventos01.jpg";
import eventos02 from "../../Assets/QuemSomos/eventos02.jpg";
import eventos03 from "../../Assets/QuemSomos/eventos03.png";
import eventos04 from "../../Assets/QuemSomos/eventos04.png";
import eventos05 from "../../Assets/QuemSomos/eventos05.png";
import eventos06 from "../../Assets/QuemSomos/eventos06.png";
import office01 from "../../Assets/QuemSomos/office01.jpg";
import office02 from "../../Assets/QuemSomos/office02.jpg";
import office03 from "../../Assets/QuemSomos/office03.jpg";
import office04 from "../../Assets/QuemSomos/office04.jpg";
import office05 from "../../Assets/QuemSomos/office05.jpg";
import office06 from "../../Assets/QuemSomos/office06.jpg";
import TrabalheConosco from "../../Assets/QuemSomos/TrabalheConosco.png";
import { ModalTrabalheNaFik } from '../../Components/ModalTrabalheNaFik';


const logos = [
    { src: logoTora, alt: "Logo da Tora" },
    { src: logoABRH, alt: "Logo da ABRH" },
    { src: logoVinci, alt: "Logo da Vinci" },
    { src: logoCJF, alt: "Logo do CJF" },
    { src: logoKontabilita, alt: "Logo da Kontabilita" },
    { src: logoGrupoZelo, alt: "Logo do Grupo Zelo" },
    { src: logoGugs, alt: "Logo do Gugs" },
    { src: logoClubeChalezinho, alt: "Logo do Clube Chalezinho" },
    { src: logoAiltoFerreiraFariaAdvAssociados, alt: "Logo do Ailton Ferreira Faria Advogados Associados" },
    { src: logoAeC, alt: "Logo da AeC" },
    { src: logoRobbyson, alt: "Logo do Robbyson" },
    { src: logoVerdemar, alt: "Logo do Verdemar" },
];

const images = [
    { src: office01, alt: "Foto do escritório da CEO da empresa, com uma mesa grande, plantas, cadeiras, e notebooks" },
    { src: office02, alt: "Foto da área de descanso da empresa, com mesas, cadeiras, plantas, sofá, e aparelho de TV" },
    { src: office03, alt: "Foto da área de descanso da empresa de outro ângulo, com mesas, cadeiras, plantas, sofá, e a saída para o corredor" },
    { src: office04, alt: "Foto de duas cadeiras da área de recepção da empresa, e atrás delas a sala de reuniões" },
    { src: office05, alt: "Foto da sala de reuniões, mostrando mesa, cadeiras, objetos de escritório, e aparelho de TV" },
    { src: office06, alt: "Foto da área principal de trabalho da empresa, mostrando mesas, cadeiras, e diversos notebooks" },

];

const imagesEventos = [
    { src: eventos01, alt: "Evento: RPA & Ai Congress - Rio de Janeiro. Colaborador Daniel Rabelo fazendo demonstrações aos participantes do evento." },
    { src: eventos02, alt: "Evento: RPA & Ai Congress - Rio de Janeiro. Palestra da CEO da empresa, Thais Alcantara." },
    { src: eventos03, alt: "Evento: RPA & Ai Congress - São Paulo. Foto do estande da empresa." },
    { src: eventos04, alt: "Evento: RPA & Ai Congress - São Paulo. Palestra da CEO da empresa, Thaís Alcantara." },
    { src: eventos05, alt: "Evento: RH LeaderShip Xperience - São Paulo. Palestra da CEO da empresa, Thaís Alcantara." },
    { src: eventos06, alt: "Palestra da CEO da empresa. Foto do estande da empresa." },
];

export const PageQuemSomos = () => {

    const [visibleLogos, setVisibleLogos] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { handleClick, visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik } = useContext(FikContext);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => {
                const nextIndex = prevIndex + 3;
                return nextIndex % logos.length;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setVisibleLogos(logos.slice(currentIndex, currentIndex + 3));
    }, [currentIndex]);

    const imageTemplate = (image) => {
        return (
            <div style={{ position: 'relative', zIndex: -1 }}>
                <img
                    src={image.src}
                    alt={image.alt}
                    style={{
                        width: '100vw',
                        borderRadius: '0px',
                        objectFit: 'cover'
                    }}
                />
            </div>
        );
    };

    const imageTemplateEventos = (image) => {
        return (
            <div
                style={{
                    padding: '20px 20px',
                    boxSizing: 'border-box',
                }}
            >
                <img
                    src={image.src}
                    alt="Evento"
                    style={{
                        width: '90%',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '20px',
                        minWidth: '50px',
                    }}
                />
            </div>
        );
    };


    const customPrevIcon = <FaArrowLeft style={{ fontSize: '1.5em', color: 'rgba(255, 255, 255, 0.5)' }} />;
    const customNextIcon = <FaArrowRight style={{ fontSize: '1.5em', color: 'rgba(255, 255, 255, 0.5)' }} />;


    return (
        <ContainerQuemSomos>
            <ModalTrabalheNaFik />
            <div className="containerAzulFaixaDoTopo">
                <div className="top"> </div>
                <div className="conteudoFaixaDoTopo">
                    <h3>ENTENDER, CRIAR, RESOLVER E SURPREENDER.</h3>
                </div>
                <div className="footFaixaDoTopo"></div>
            </div>
            <div className="textosDoTopo">
                <h3>
                    Estamos na linha de frente da transformação digital através de <b>desenvolvimento de software.</b>
                </h3>
                <h4>
                    SOMOS <br />TECNOLOGIA!
                </h4>
            </div>
            <BarraPrincipaisMercados />
            <div className="footPrincipaisMercados"></div>
            <Galleria
                value={images}
                item={imageTemplate}
                showThumbnails={false}
                circular={true}
                autoplayInterval={3000}
                showIndicators={false}
                showItemNavigators
                prevIcon={customPrevIcon}
                nextIcon={customNextIcon}
            />
            <div className="footFaixaBranca"></div>
            <MidSection>
                <MidTitle>
                    NOSSA HISTÓRIA
                </MidTitle>
                <ButtonGroup>
                    <NonClickableBlueButton>
                        <h3>
                            2021
                        </h3>
                        <h4>
                            Nascemos como
                            empresa
                            independente com
                            nome Fiskal Digital.
                        </h4>
                        <h4>
                            Iniciando no mercado
                            com as soluções
                            LMS, JOBS e Portal
                            Digital.
                        </h4>
                    </NonClickableBlueButton>

                    <NonClickableBlueButton>
                        <h3>
                            2022
                        </h3>
                        <h4>
                            Iniciamos a solução
                            RPA.
                        </h4>
                        <h4>
                            Crescemos o nosso
                            time e mudamos para
                            um novo espaço
                            altamente tecnológico
                            e agradável para os
                            nossos colaboradores.
                        </h4>
                    </NonClickableBlueButton>

                    <NonClickableBlueButton>
                        <h3>
                            2023
                        </h3>
                        <h4>
                            Disponibilizamos o
                            serviço de Consultoria
                            e BPO TECH utilizando
                            metodologia ágil e
                            simplificando
                            processos com
                            redução de custos
                            operacionais.
                        </h4>
                        <h4>
                            Fechamos o ano com o
                            dobro de
                            colaboradores.
                        </h4>
                    </NonClickableBlueButton>

                    <NonClickableBlueButton>
                        <h3>
                            2024
                        </h3>
                        <h4>
                            Em 2024, nossa empresa
                            passou por um rebranding,
                            fortalecendo nossa
                            identidade e
                            posicionamento no
                            mercado.
                        </h4>
                        <h4>
                            Estamos otimistas em
                            relação às perspectivas
                            para este ano, focados em
                            inovação e manter a
                            excelência em nossos
                            produtos/serviços. Agora,
                            somos ainda mais
                            tecnologia!
                        </h4>
                    </NonClickableBlueButton>
                </ButtonGroup>
            </MidSection>
            <MidSection>
                <MidTitle>
                    POLÍTICA DE SERVIÇOS
                </MidTitle>
                <TransparentButtonGroup>
                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            VISÃO GERAL
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Na FIK DIGITAL nos dedicamos a fornecer
                            serviços de desenvolvimento de software
                            de alta qualidade para atender às
                            necessidades e expectativas de nossos
                            clientes. Nossa política de serviços visa
                            garantir a satisfação do cliente, fornecendo
                            soluções personalizadas, confiáveis e
                            inovadoras.
                        </TransparentButtomTextContainer>
                    </TransparentButton>

                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            COMPROMISSO COM A QUALIDADE
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Nosso compromisso com a qualidade é
                            fundamental em tudo o que fazemos.
                            Buscamos constantemente a excelência
                            em nossos processos de desenvolvimento
                            de software, aderindo às melhores práticas
                            da indústria e garantindo que nossos
                            produtos atendam aos mais altos padrões
                            de qualidade.
                        </TransparentButtomTextContainer>
                    </TransparentButton>
                </TransparentButtonGroup>

                <TransparentButtonGroup>
                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            TRANSPARÊNCIA E COMUNICAÇÃO
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Valorizamos a transparência e a
                            comunicação aberta com nossos clientes.
                            Estamos comprometidos em manter nossos
                            clientes informados durante todo o
                            processo de desenvolvimento do software,
                            fornecendo atualizações regulares sobre o
                            progresso do projeto e respondendo
                            prontamente a quaisquer perguntas ou
                            preocupações.
                        </TransparentButtomTextContainer>
                    </TransparentButton>

                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            PERSONALIZAÇÃO E FLEXIBILIDADE
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Nosso compromisso com a qualida.de é
                            fundamental em tudo o que fazemos.
                            Buscamos constantemente a excelência
                            em nossos processos de desenvolvimento
                            de software, aderindo às melhores práticas
                            da indústria e garantindo que nossos
                            produtos atendam aos mais altos padrões
                            de qualidade.
                        </TransparentButtomTextContainer>
                    </TransparentButton>
                </TransparentButtonGroup>

                <TransparentButtonGroup>
                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            SEGURANÇA E CONFIDENCIALIDADE
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            A segurança e a confidencialidade dos
                            dados de nossos clientes são de extrema
                            importância para nós. Implementamos
                            medidas rigorosas de segurança para
                            proteger as informações do cliente contra
                            acesso não autorizado ou uso indevido, e
                            nos comprometemos a manter a
                            confidencialidade de todas as informações
                            confidenciais compartilhadas conosco.
                        </TransparentButtomTextContainer>
                    </TransparentButton>

                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            SUPORTE CONTÍNUO
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Nosso compromisso com os clientes não
                            termina após a entrega do projeto.
                            Oferecemos suporte contínuo
                            pós-implantação para garantir que nossos
                            clientes estejam satisfeitos com o
                            desempenho do software e para fornecer
                            assistência adicional, conforme necessário.
                        </TransparentButtomTextContainer>
                    </TransparentButton>
                </TransparentButtonGroup>

                <TransparentButtonGroup>
                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            FEEDBACK E MELHORIA CONTÍNUA
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Valorizamos o feedback de nossos clientes
                            e estamos sempre buscando maneiras de
                            melhorar nossos serviços. Encorajamos os
                            clientes a compartilhar suas opiniões e
                            sugestões conosco, para que possamos
                            implementar melhorias contínuas e garantir
                            a excelência em tudo o que fazemos.
                        </TransparentButtomTextContainer>
                    </TransparentButton>

                    <TransparentButton>
                        <NonClickableLittleBlueButton />
                        <TransparentButtomTextBoldContainer>
                            CONFORMIDADE LEGAL E ÉTICA
                        </TransparentButtomTextBoldContainer>
                        <TransparentButtomTextContainer>
                            Comprometemo-nos a operar em
                            conformidade com todas as leis e
                            regulamentações aplicáveis e a aderir aos
                            mais altos padrões éticos em todas as
                            nossas atividades comerciais. Nossa
                            conduta é guiada pela integridade,
                            honestidade e respeito pelos direitos e
                            interesses de nossos clientes.
                        </TransparentButtomTextContainer>
                    </TransparentButton>
                </TransparentButtonGroup>
            </MidSection>
            <MidSection>
                <div className="containerCasesDeSucesso">
                    <div className="cardCasesDeSucesso">
                        <div>
                            <h3>PARCERIAS QUE TRANSFORMAM:</h3 >
                            <h4>CASES DE SUCESSO COM A FIK DIGITAL</h4>
                        </div>

                        <div className="containerImagensLogoClientes">
                            {visibleLogos.map((logo, index) => (
                                <img
                                    key={index}
                                    className="imagensLogoClientes"
                                    src={logo.src}
                                    alt={logo.alt}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </MidSection>
            <MidSection>
                <MinorMidTitle>
                    CASOS DE SUCESSO ENTRE
                </MinorMidTitle>
                <BlackMidTitle>
                    TECNOLOGIA, PESSOAS, E EMPRESAS.
                </BlackMidTitle>
                <BlackLittleMidTitle>
                    Nosso objetivo é
                </BlackLittleMidTitle>
                <OtherMidTitle>
                    SIMPLIFICAR PROCESSOS.
                </OtherMidTitle>
                <TextsContainer>
                    <LeftText>
                        Através da tecnologia sua empresa
                        pode viver uma nova rotina mais leve,
                        com soluções que realizam todas as
                        atividades repetitivas de maneira
                        infinitamente mais rápida e assertiva à
                        que você já está acostumado.
                    </LeftText>
                    <RightText>
                        Nossos robôs interagem com todos os tipos de sistemas
                        e plataformas. Construímos portais para uso interno e
                        externo possibilitando que as áreas administrativas
                        controlem e acompanhem todas as tarefas e relatórios,
                        assim como estoques, roteirização, cotação e etc. Sua
                        empresa ganha qualidade, credibilidade e governança, ao
                        mesmo tempo que reduz custo e tempo.
                    </RightText>
                </TextsContainer>
                <MaosContainer src={Maos} alt="Mão humana e mão robótica" />
            </MidSection>
            <BottomSection>
                <div className="containerAzulEventos">
                    <div className="top"> </div>
                    <div className="conteudoEventos" id='Eventos'>
                        <FaixaEventosTextContainer>
                            <FaixaEventosTitle >
                                EVENTOS
                            </FaixaEventosTitle>
                            <FaixaEventosButton>
                                <h3>
                                    Conheça, tire dúvidas, converse e
                                    troque ideias, sobre cada uma de nossas
                                    soluções com o nosso time pessoalmente
                                </h3>
                            </FaixaEventosButton>
                        </FaixaEventosTextContainer>
                        <Carousel
                            value={imagesEventos}
                            itemTemplate={imageTemplateEventos}
                            numVisible={3}
                            numScroll={1}
                            autoplayInterval={3000}
                            prevIcon={customPrevIcon}
                            nextIcon={customNextIcon}
                            circular={true}
                            showArrows={true}
                        />
                    </div>
                    <div className="footEventos"></div>
                </div>
            </BottomSection>
            <BottomSection>
                <TrabalheConoscoContainer>
                    <TrabalheConoscoImage src={TrabalheConosco} alt="Foto dos colaboradores da empresa FikDigital em uma escadaria" />
                    <TrabalheConoscoTextContainer>
                        <BottomTitle >

                            TRABALHE<br />CONOSCO
                        </BottomTitle>
                        <BlackBottomSubTitle>
                            NÓS RESPIRAMOS INOVAÇÃO.
                        </BlackBottomSubTitle>
                        <BottomText>
                            Desde o momento em que você entra
                            pela porta, você será cercado por uma
                            equipe de mentes estendidas e
                            apaixonadas, prontas para desafiar o
                            status quo e transformar ideias em
                            realidade. <br />
                            <Button ariaLabel='Fale com nosso time pelo whatsapp.' onClick={() => setVisibleModalTrabalheNaFik(true)}>
                                FAÇA PARTE DO TIME!
                            </Button>
                        </BottomText>
                    </TrabalheConoscoTextContainer>
                </TrabalheConoscoContainer>
            </BottomSection>

        </ContainerQuemSomos>
    );
};
