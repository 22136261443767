import { FikAutomatonStyle, FikAutomatonStyle2, FikAutomatonStyle3, FikAutomatonStyle4 } from "./style";
import { Faixas } from "../../Components/FikAutomation/Faixas";
import cover from "../../Assets/FikAutomation/RoboFIKautomation-01.png";
import rightCover from "../../Assets/FikAutomation/FuncionariosFikautomation.png"
import funcionario from "../../Assets/FikAutomation/FuncionariaFikAutomation-01.png"
import { useContext } from "react";
import FikContext from "../../Contexts/FikSiteContext";

export const FikAutomation = () => {
    const { handleClick } = useContext(FikContext);
    return (
        <>
            <FikAutomatonStyle>
                <div className="left">
                    <div>
                        <h2>RPA + <br />
                            FIK AUTOMATION</h2>

                        <p>Com RPA, processos que exigem muita interação humana podem ser automatizados. O FIK Automation, atua como um orquestrador inovador, que gerencia os robôs e assegura governança completa nas operações de automação</p>
                        <button ariaLabel='Fale com nosso time pelo whatsapp.' onClick={handleClick}>FALE COM O NOSSO TIME</button>
                    </div>
                </div>
                <div className="right">
                    <div className="gradient">
                    </div>
                    <img src={cover} alt="imagem de um robô segurando notebook e ao fundo imagens de circuitos" />
                </div>
            </FikAutomatonStyle>

            <FikAutomatonStyle2>
                <div className="containerBranco">
                    <div className="top"> </div>
                    <div className="conteudo2">
                        <section>
                            <div className="esquerda">
                                <h2>
                                    ORQUESTRAÇÃO DE <br />
                                    <strong>PROCESSOS</strong> <br />
                                    AUTOMATIZADOS
                                </h2>

                                <p>Desenhado para transformar a gestão de <br />
                                    automações em uma tarefa simples e eficiente o <br />
                                    FIK Automation oferece um conjuto robusto de <br />
                                    funcionalidades para maximizar a eficiência <br />
                                    operacional e o controle sobre suas automações.</p>
                            </div>
                            <div className="direita">
                                <img src={rightCover} alt="duas pessoas olhando para um laptop e sorrindo, aparentemente comemorando. A mulher está sentada, usando camisa social, e o homem está ao lado, de pé, usando óculos e um suéter, com expressão de entusiasmo. Ao fundo, há um ambiente corporativo." />
                            </div>

                        </section>

                    </div>
                    <div className="foot">
                    </div>
                </div>
            </FikAutomatonStyle2>

            <FikAutomatonStyle3>

                <div className="containerAzul">
                    <div className="top"> </div>
                    <div className="conteudo">
                        <div className="faixasContainer">
                            <div className="title">
                                <h2>PRINCIPAIS FUNCIONALIDADES:</h2>
                            </div>{
                                <Faixas />
                            }
                        </div>
                    </div>
                </div>
                <div className="foot"></div>

            </FikAutomatonStyle3>

            <FikAutomatonStyle4 image={funcionario}>
                <div className="left" ></div>
                <div className="right"
                    alt="Descrição da imagem: Uma mulher jovem em trajes formais, com um blazer cinza, está segurando um laptop aberto em uma das mãos. Ela está olhando para o lado, com uma expressão confiante. Ao fundo, há elementos gráficos abstratos em azul, sugerindo conectividade ou tecnologia, com linhas e círculos conectados. É o logo da Fik Digital.">
                    <h2><strong>BENEFÍCIOS DE <br /> </strong>
                        IMPLEMENTAÇÃO</h2>

                    <p>Com o FIK Automation, sua empresa terá o poder de <br />
                        gerenciar automações de forma eficiente, segura e <br />
                        flexivel. Eleve o nível de suas operações com a solução <br />
                        completa para orquestração de robôs em RPA</p>
                </div>
            </FikAutomatonStyle4>
        </>
    );
};
