import logo from '../../Assets/LogoFik/logo.png';
import { FooterRetorno } from "./style";
import iconTelefone from '../../Assets/Icons/telefone .svg';
import iconEmail from '../../Assets/Icons/Email.svg';
import iconInstagram from '../../Assets/Icons/instagramIcon.svg';
import iconLinkedin from '../../Assets/Icons/linkedinIcon.svg';
import iconYoutube from '../../Assets/Icons/YoutubeIcon.svg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import he from 'he';

export const FooterComponent = () => {
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
    });

    const location = useLocation();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const htmlContent = `
            <h1>Contato pelo Footer</h1>
            <p><strong>Nome:</strong> ${he.encode(formData.nome)}</p>
            <p><strong>Email:</strong> ${he.encode(formData.email)}</p>
            <p><strong>Pagina visitada no momento:</strong> ${he.encode(location.pathname)}</p>
        `;

        const payload = {
            email: formData.email,
            subject: `Contato capturado no footer do site.`,
            html: htmlContent
        };

        try {
            const response = await fetch('https://sendmail.fikdigital.com.br/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Erro na requisição');
            } else {
                setFormData({ nome: '', email: '' });
            }


            const resultado = await response.text();
            console.log('Resposta do servidor:', resultado);
        } catch (error) {
            console.error('Erro:', error);
        }
    };

    return (
        <FooterRetorno>
            <div className='containerHeader'>
                <div className="footer-containerInformacoesContato">
                    <div className="footer-column" aria-labelledby="contact-info">
                        <div className="logoContainer">
                            <img className="Logo" src={logo} alt="Logo da FIK DIGITAL." />
                        </div>
                        <address>
                            <p className='containerIconEinformacao'>
                                <img className='iconsFooter' src={iconTelefone} alt="ícone de telefone ao lado do numero de contato fixo." />
                                <span className='spanConfig'>(31) 3222-6858</span>
                            </p>
                            <p className='containerIconEinformacao'>
                                <img className='iconsFooter' src={iconEmail} alt="ícone de email." />
                                <span className='spanConfig'>contato@fikdigital.com.br</span>
                            </p>
                            <p className='containerIconEinformacao'>
                                <iframe
                                    title='mapa do google maps, com localização da FIK.'
                                    className='mapaFik'
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d480121.3196980586!2d-43.938615!3d-19.926604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa699e6bc0887d7%3A0x1d86f9608466a348!2sFIK%20Digital!5e0!3m2!1spt-BR!2sus!4v1726084806557!5m2!1spt-BR!2sus"
                                    width="" height="" style={{ border: 0 }} allowFullScreen=""
                                    loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </p>
                        </address>
                    </div>
                    <p className='textDireitosReservados'>@ todos os direitos reservados</p>
                </div>

                <div className="footer-containerInformacoesProdutosEConteudos">
                    <nav>
                        <h2 className='tituloDeCardFooter'>Institucional</h2>
                        <a href="/quemSomos">Sobre nós</a>
                    </nav>
                    <nav className='navProdutos'>
                        <h2 className='tituloDeCardFooter'>Para Empresas</h2>
                        <a aria-label="Te enviaremos para a pagina FikAutomation" href="/fikAutomation">RPA + FIK Automation</a>
                        <a aria-label="Te enviaremos para a pagina FikSmart" href="/FikSmart">FIK Smart</a>
                        <a aria-label="Te enviaremos para a pagina FikAnalytics" href="/FikAnalytics">FIK Analytics</a>
                        <a aria-label="Te enviaremos para a pagina FikOn" href="/FikOn">FIK ON</a>
                        <a aria-label="Te enviaremos para a pagina da solução JOBS" href="/Jobs">JOBS</a>
                        <a aria-label="Te enviaremos para a pagina da consultoria" href="/consultoria">CONSULTORIA</a>
                        <a aria-label="Te enviaremos para pagina inicia, onde falamos sobre o BPO Tech" href="/#BPO">BPO TECH</a>
                    </nav>
                </div>

                <div className="footer-containerInformacoesProdutosEConteudos">
                    <nav>
                        <h2 className='tituloDeCardFooter'>Conteúdos</h2>
                        <a aria-label="Te enviaremos para a pagina nosso quadro de noticias de tecnologias no Youtube." target="_blank" href='https://www.youtube.com/playlist?list=PLolIexh1ywl-PKAj-Lt90Mz0FZK66jq6-'>FIK TECH</a>
                    </nav>
                </div>

                <div className="footer-containerInformacoesForm">
                    <div>
                        <h2 className='tituloDeCardFooter'>Receba nossas atualizações</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                id="nome"
                                aria-label="Insira seu nome"
                                placeholder="Seu nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                            <input
                                type="email"
                                id="email"
                                aria-label="Insira seu email"
                                placeholder="Seu e-mail"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <button aria-label='Envia seus dados.'>ENVIAR</button>
                        </form>

                        <div className="containerIcons">
                            <a aria-label="Abre aba para nossa pagina no Linkedin." href="https://br.linkedin.com/company/fikdigitalbr" target="_blank">
                                <img className="iconeRedeSocialFootter" src={iconLinkedin} alt="Ícone com o logo do Linkedin." />
                            </a>
                            <a aria-label="Abre aba para nossa pagina no Instagram." href="https://www.instagram.com/fikdigitalbr/" target="_blank">
                                <img className="iconeRedeSocialFootter" src={iconInstagram} alt="Ícone com o logo do Instagram." />
                            </a>
                            <a aria-label="Abre aba para nossa pagina no Youtube." href="https://www.youtube.com/@fikdigital/featured" target="_blank">
                                <img className="iconeRedeSocialFootter" src={iconYoutube} alt="Ícone com o logo do Youtube." />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='containerTextosDireitosReservados'>
                <p>@ todos os direitos reservados</p>
                <p>desenvolvido por FIK DIGITAL 2024</p>
            </div>
        </FooterRetorno>
    );
};
